import Select from 'react-select';
import ValidityItem from "./ValidityItem";

import './Validity.scss';
import React, {Component} from "react";
import ApiService from "../../../util/ApiService";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ValiditySearch from "./ValiditySearch";

const apiService = new ApiService();

class Validity extends Component {
    constructor(props) {
        super(props);
        // this.typeNoValue = React.createRef();
        this.makerValue = React.createRef();
        this.vehicleValue = React.createRef();
        this.typeValue = React.createRef();
        this.state = {
            applicability: [],
            makers: [],
            maker_value: '',
            search_types_no: '',
            list_search_types_no: [],
            vehicles: [],
            vehicle_value: '',
            type: [],
            type_value: '',
            sort_no: '',
            ts: '',
            type_no: '',
            sections: [],
            count_section: 0,
            count_criteria: 0,
            modal_open: false,
            is_type_no: '',
        }
        this.searchVehicle = this.searchVehicle.bind(this)
        this.setValueMaker = this.setValueMaker.bind(this)
        this.setValueVehicle = this.setValueVehicle.bind(this)
        this.searchType = this.searchType.bind(this)

        this.changeSortNo = this.changeSortNo.bind(this)
        this.changeTS = this.changeTS.bind(this)
        this.addApplicability = this.addApplicability.bind(this)
        this.addSection = this.addSection.bind(this)

        this.countSeqSort = this.countSeqSort.bind(this)

        this.modalOpen = this.modalOpen.bind(this)
        this.modalClose = this.modalClose.bind(this);
        this.getTypeNo = this.getTypeNo.bind(this);
        this.changeStateTypeNo = this.changeStateTypeNo.bind(this);

        this.getTypeNoSearchModal = this.getTypeNoSearchModal.bind(this);
        this.enterOpenModal = this.enterOpenModal.bind(this);


    }

    componentDidMount() {
        this.getApplicability()
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({makers: nextProps.makers}, () => this.getApplicability())
    }

    modalOpen() {
        this.setState({modal_open: true})
    }

    modalClose() {
        this.setState({modal_open: false})
    }

    changeSortNo(e) {
        this.setState({sort_no: e.target.value})
    }

    changeTS(e) {
        this.setState({ts: e.target.value})
    }

    getApplicability() {
        apiService.getApplicability(this.props.art_no_id).then((result) => {
            this.setState({applicability: result.vehicles})
        })
    }

    addApplicability() {
        if (this.state.sort_no === '' || this.state.ts === ''){
            alert("Вы не заполнили обязательные поля")
        } else {
            apiService.createApplicability(
            this.props.art_no_id, this.props.gen_art_no, this.state.ts, this.state.type_no, this.state.sort_no
        ).then(() => {
                window.location.reload();
                // this.getApplicability()
            }
        )
        }
    }

    addSection(crit_no, crit_val, ts, type_no, validity = 0) {
        // let _crit_val
        // let value
        // try {
        //     _crit_val = crit_val.value_num
        //     value = crit_val.label
        // } catch (e) {
        //     _crit_val = crit_val
        //     value = crit_val
        // }
        apiService.createSection(
            // this.props.art_no_id, crit_no.value_of_num, _crit_val, this.props.gen_art_no, ts, type_no, validity === 0 ?
            this.props.art_no_id, crit_no, crit_val, this.props.gen_art_no, ts, type_no, validity === 0 ?
                this.state.count_section + 1 : validity, this.state.count_criteria + 1
        ).then(() => {
                // this.setState({
                //     sections: {
                //         "sort_no": this.state.count_criteria + 1,
                //         "crit_no": crit_no.label,
                //         "crit_val": value
                //     }
                // })
                window.location.reload();
            }
        )
    }

    setValueMaker(e) {
        try {
            this.setState({maker_value: e.label}, () => this.searchVehicle())
        } catch (e) {
        }
    }

    setValueVehicle(e) {
        try {

            this.setState({vehicle_value: e.label}, () => this.searchType())
        } catch (e) {
            // console.log("error", e.label)

        }
    }

    searchVehicle() {
        const self = this;
        if (this.state.maker_value !== '') {
            apiService.searchVehicles(this.state.maker_value.split(("/ "))[1]).then(function (result) {

                let vehicles = [];
                result.list_model.forEach(function (item, index) {
                    vehicles.push({"value": index + 1, "label": item.name_model})
                });
                self.setState({vehicles: vehicles})
            });
        }
    }

    getTypeNo(lexem) {
        apiService.getTypesNo(lexem).then((result) => {
            let search_types_no = [];
            result.forEach(function (item, index) {
                search_types_no.push(
                    {
                        // "value": index + 2,
                        "label": " (Брэнд: " + item.model_id__brand_id__term_plain
                            + ") (Модель: " + item.model_id__name_model
                            + ") (Тип: " + item.name_type
                            + ") (Двигатель: " + item.engine
                            + ") (Год: " + item.year
                            + ") (Номер: " + item.type_no
                            + ")",
                        "type_num": item.type_no
                    }
                )
            });
            this.setState({
                list_search_types_no: [...search_types_no],
            })
        });
    }

    searchType(type_no) {
        this.setState({type_no: type_no}, () => {
            this.modalClose()
        })


        const self = this;
        if (this.state.vehicle_value !== '') {
            apiService.searchType(this.state.maker_value.split(("/ "))[1], this.state.vehicle_value).then(function (result) {

                let type = [];
                result.list_type.forEach(function (item, index) {
                    type.push({
                        "value": index + 1,
                        "label": item.engine + ", " +
                            item.ls_ls + ", " +
                            item.model_id + ", " +
                            item.name_type + ", " +
                            item.type_no + ", " +
                            item.year,
                        "type_no": item.type_no,
                        "type_engine": item.engine,
                        "type_ls_ls": item.ls_ls,
                        "type_name": item.type_no,
                        "type_year": item.year,
                        "DRIVE_TYPE": item.DRIVE_TYPE,
                        "BODY_TYPE": item.BODY_TYPE,
                        "VEH_MODEL_INTL": item.VEH_MODEL_INTL,
                        "VEH_TYPE_NAME": item.VEH_TYPE_NAME,
                        "CCM_TECH": item.CCM_TECH,
                        "CYLINDERS": item.CYLINDERS,
                        "ENGINE_TYPE": item.ENGINE_TYPE,
                        "KW": item.KW,
                        "LITRES": item.LITRES,
                        "VALVES_PER_CHAMBER": item.VALVES_PER_CHAMBER,
                        "FUEL_MIXTURE_FORMATION": item.FUEL_MIXTURE_FORMATION,
                        "ts": item.ts,

                    })

                });
                self.setState({type: type})
            });
        }
    }

    countSeqSort(count_section = 0, count_criteria = 0) {
        this.setState({
            count_section: count_section,
            count_criteria: count_criteria
        })
    }

    setDisabled() {
        return this.state.type_no === '' || this.state.type_no === undefined;
    }

    changeStateTypeNo(e) {
        this.setState({type_no: e.type_num})
    }


    getTypeNoSearchModal(e) {
        const search_type_no = e.target.value

        apiService.getTypesNo(search_type_no).then((result) => {
            let search_t = [];
            result.forEach(function (item, index) {
                // search_t.push({
                //         "value": index + 1,
                //         "brand": item.model_id__brand_id__term_plain,
                //         "model": item.model_id__name_model,
                //         "type_no": item.type_no,
                //         "type_engine": item.engine,
                //         "type_ls_ls": item.ls_ls,
                //         "type_name": item.name_type,
                //         "type_year": item.year,
                //         "DRIVE_TYPE": item.DRIVE_TYPE,
                //
                //     })
                search_t.push({
                        "value": index + 1,
                        "label": item.engine + ", " +
                            item.ls_ls + ", " +
                            item.model_id + ", " +
                            item.name_type + ", " +
                            item.type_no + ", " +
                            item.year,
                        "type_no": item.type_no,
                        "type_engine": item.engine,
                        "type_ls_ls": item.ls_ls,
                        "type_name": item.type_no,
                        "type_year": item.year,
                        "DRIVE_TYPE": item.DRIVE_TYPE,
                        "BODY_TYPE": item.BODY_TYPE,
                        "VEH_MODEL_INTL": item.VEH_MODEL_INTL,
                        "VEH_TYPE_NAME": item.VEH_TYPE_NAME,
                        "CCM_TECH": item.CCM_TECH,
                        "CYLINDERS": item.CYLINDERS,
                        "ENGINE_TYPE": item.ENGINE_TYPE,
                        "KW": item.KW,
                        "LITRES": item.LITRES,
                        "VALVES_PER_CHAMBER": item.VALVES_PER_CHAMBER,
                        "FUEL_MIXTURE_FORMATION": item.FUEL_MIXTURE_FORMATION,
                        "ts": item.ts,

                    })
            });
            this.setState({
                type: search_t,
                is_type_no: search_t
            })
        });

    }

    enterOpenModal(e) {
        if (e.key === "Enter") {
            this.modalOpen()
        }
    }

    render() {
        return (
            <>
                <div className="data-block">
                    <div className="data-block__head">
                        <div className="data-block__title">Применимость</div>
                    </div>

                    <div className="data-block__content">
                        <div className="data-block__grid data-block__grid--validity2">

                            <fieldset className="fg data-block__col data-block__col3">
                                <label>SortNo для 404</label>
                                <input type="text"
                                       onChange={this.changeSortNo}
                                />
                            </fieldset>

                            <fieldset className="fg data-block__col data-block__col4">
                                <label>ТС</label>
                                <input type="text"
                                       onChange={this.changeTS}
                                />
                            </fieldset>
                            <fieldset className="fg data-block__col data-block__col6">
                                <label>Производитель </label>
                                <Select
                                    ref={this.makerValue}
                                    classNamePrefix="select"
                                    isSearchable={true}
                                    name="maker"
                                    options={this.state.makers}
                                    onChange={this.setValueMaker}
                                    onInputChange={this.props.funcSearchMakers}
                                    placeholder={'Поиск'}
                                />
                            </fieldset>
                        </div>
                        <div className="data-block__grid data-block__grid--validity">
                            <fieldset className="fg data-block__col data-block__col7">
                                <label>Модель </label>
                                <Select
                                    ref={this.vehicleValue}
                                    classNamePrefix="select"
                                    isSearchable={true}
                                    name="vehicle"
                                    options={this.state.vehicles}
                                    onChange={this.setValueVehicle}
                                    onInputChange={''}
                                    placeholder={'Поиск'}
                                />
                            </fieldset>
                            <fieldset className="fg data-block__col data-block__col6">
                                <label>Тип </label>
                                <input type="text"
                                       value={this.state.type_no}
                                       onClick={this.modalOpen}
                                       onKeyDown={this.enterUpdate}
                                       placeholder={"Поиск"}
                                />
                            </fieldset>
                            <Button
                                onClick={this.addApplicability}
                                className="data-block__add-btn btn btn-blue"
                                style={{marginRight: 7, minWidth: 150, backgroundColor: '#6D71F9'}}
                                disabled={this.setDisabled()}

                            >
                                Добавить
                            </Button>

                        </div>
                        {/*<div className="data-block__head">*/}
                        {/*    <div className="data-block__title">Поиск Автомобилей по Type Number</div>*/}
                        {/*</div>*/}
                        <div className="data-block__grid data-block__grid--validity">
                            <fieldset className="fg data-block__col data-block__col1">
                                <label>SortNo для 404</label>
                                <input type="text"
                                       onChange={this.changeSortNo}
                                       placeholder={"Обязательное поле"}
                                />
                            </fieldset>

                            <fieldset className="fg data-block__col data-block__col1">
                                <label>ТС</label>
                                <input type="text"
                                       onChange={this.changeTS}
                                       placeholder={"Обязательное поле"}
                                />
                            </fieldset>
                            <fieldset className="fg data-block__col data-block__col4">
                                <label>TypeNo</label>
                                <input type="text"
                                    value={this.state.type_no}
                                    placeholder={"НЕ для ввода"}
                                />
                                {/*<Select*/}
                                {/*    classNamePrefix="select"*/}
                                {/*    name="getTypeNo"*/}
                                {/*    options={this.state.list_search_types_no}*/}
                                {/*    onInputChange={this.getTypeNo}*/}
                                {/*    onChange={this.changeStateTypeNo}*/}
                                {/*    placeholder={"поиск"}*/}
                                {/*/>*/}
                            </fieldset>

                            <fieldset className="fg data-block__col data-block__col4">
                                <label>TypeNoTable Search</label>
                                <input type="text"
                                    onChange={this.getTypeNoSearchModal}
                                    onKeyDown={this.enterOpenModal}
                                    placeholder={"Поиск ENTER"}
                                />
                            </fieldset>
                            <Button
                                onClick={this.addApplicability}
                                className="data-block__add-btn btn btn-blue"
                                style={{marginRight: 7, minWidth: 150, backgroundColor: '#6D71F9'}}
                                disabled={this.setDisabled()}

                            >
                                Добавить
                            </Button>
                        </div>
                        <div className="table validity-table">
                            <table>
                                {this.state.applicability.map((validity, index) =>
                                    <ValidityItem
                                        index={index}
                                        ts={validity.ts}
                                        type_no={validity.type_no}
                                        brand={validity.brand}
                                        model={validity.model}
                                        type={validity.type}
                                        validity={validity.sections}
                                        funcAddSection={this.addSection}
                                        funcCountSeqSort={this.countSeqSort}
                                        new_section={this.state.sections}
                                        art_no_id={this.props.art_no_id}
                                    />
                                )}
                            </table>
                        </div>
                    </div>
                </div>

                <Modal centered show={this.state.modal_open} onHide={this.modalClose}>

                    <Modal.Body>
                        <table className="table">
                            <thead>
                            <tr>
                                <th scope="col">Номер</th>
                                <th scope="col">ts</th>
                                <th scope="col">Модель</th>
                                <th scope="col">Тип</th>
                                <th scope="col">Двигатель</th>
                                <th scope="col">л.с.</th>
                                <th scope="col">Drive Type</th>
                                <th scope="col">BODY_TYPE</th>
                                <th scope="col">CCM_TECH</th>
                                <th scope="col">CYLIN</th>
                                <th scope="col">ENGINE_TYPE</th>
                                <th scope="col">KW</th>
                                <th scope="col">LITRES</th>
                                <th scope="col">VAL_PER_CHA</th>
                                <th scope="col">FUEL_MIX_FORM</th>
                                <th scope="col">Год с</th>
                                <th scope="col">Год до</th>
                            </tr>
                            </thead>
                            <tbody>
                                {this.state.type.map((type, index) =>
                                    <ValiditySearch
                                        index={index}
                                        type={type}
                                        funcSearchType={this.searchType}
                                    />
                                )}
                            </tbody>
                        </table>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}


export default Validity;