import React, {Component} from "react";

class ValiditySearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: [],
        }
        this.changeType = this.changeType.bind(this)
    }
    componentDidMount() {
        this.setState({type: this.props.type})
    }

    changeType(e) {
        this.props.funcSearchType(e.target.lastChild.data)
    }

    splitYears() {
        let year = this.props.type.type_year
        let start_year = year.split('-')[0];
        let end_year = year.split('-')[1];
        return {"start_year": start_year, "end_year": end_year}
    }

    render() {
        return (
            <tr
            >
                <td
                    style={{cursor: "pointer", color: "blue"}}
                    onClick={this.changeType}
                >
                    {this.props.type.type_no}
                </td>
                <td>
                    {this.props.type.ts}
                </td>
                <td>
                    {this.props.type.VEH_MODEL_INTL}
                </td>

                <td>
                    {this.props.type.VEH_TYPE_NAME}
                </td>

                <td>
                    {this.props.type.type_engine}
                </td>
                <td>
                    {this.props.type.type_ls_ls}
                </td>
                <td>
                    {this.props.type.DRIVE_TYPE}
                </td>
                <td>
                    {this.props.type.BODY_TYPE}
                </td>
                <td>
                    {this.props.type.CCM_TECH}
                </td>
                <td>
                    {this.props.type.CYLINDERS}
                </td>
                <td>
                    {this.props.type.ENGINE_TYPE}
                </td>
                <td>
                    {this.props.type.KW}
                </td>
                <td>
                    {this.props.type.LITRES}
                </td>
                <td>
                    {this.props.type.VALVES_PER_CHAMBER}
                </td>
                <td>
                    {this.props.type.FUEL_MIXTURE_FORMATION}
                </td>
                <td>
                    {this.splitYears().start_year}
                </td>
                <td>
                    {this.splitYears().end_year}
                </td>

            </tr>
        );
    }
}


export default ValiditySearch;
