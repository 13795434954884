import './App.scss';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Home from "./pages/Home/Home";
import Edit from "./pages/Edit/Edit";
import React, {Component} from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {Form} from "react-bootstrap";

import ApiService from "./util/ApiService";

const apiService = new ApiService();

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            displayed_input: true,
            displayed_signup: false,
            displayed_login: false,
            logged_in: !!localStorage.getItem('token'),
            username: '',
            password: ''
        };
        this.login_change = this.login_change.bind(this)
        this.pass_change = this.pass_change.bind(this)
        this.handle_signup = this.handle_signup.bind(this)
        this.handle_login = this.handle_login.bind(this)
        this.handle_logout = this.handle_logout.bind(this)
        this.close_signup = this.close_signup.bind(this);
        this.close_login = this.close_login.bind(this);
        this.eventLoginEnter = this.eventLoginEnter.bind(this);
        this.eventSignupEnter = this.eventSignupEnter.bind(this);
    }

    login_change(e) {
        this.setState({username: e.target.value});
    };

    pass_change(e) {
        this.setState({password: e.target.value});
    };

    handle_login() {
        apiService.logIn(this.state.username, this.state.password).then(json => {
            localStorage.setItem('token', json.auth_token);
            localStorage.setItem('username', this.state.username);
            this.setState({
                logged_in: true,
                displayed_login: false
            });
        }).catch((error) => {
            if (error.response.data.username) {
                alert(error.response.data.username)
            } else if (error.response.data.password) {
                alert(error.response.data.password)
            } else if (error.response.data.non_field_errors) {
                alert(error.response.data.non_field_errors
                    + ' Возможно, Ваша учетная запись требует подтверждения. Обратитесь к администратору.')
            } else {
                alert(error)
            }
        })
    };

    handle_signup() {
        apiService.signUp(this.state.username, this.state.password).then(() => {
            this.setState({
                displayed_signup: false,
                displayed_login: true
            });
        }).catch((error) => {
            if (error.response.data.username) {
                alert(error.response.data.username)
            } else if (error.response.data.password) {
                alert(error.response.data.password)
            } else {
                alert(error)
            }
        })
    };

    handle_logout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('username');
        this.setState({logged_in: false, username: '', displayed_input: true});
    };

    close_signup() {
        this.setState({
            displayed_signup: false,
            displayed_input: true,
        });
    }

    close_login() {
        this.setState({
            displayed_login: false,
            displayed_input: true,
        });
    }

    eventLoginEnter(e) {
        if (e.key === "Enter") {
            this.handle_login()
        }
    }

    eventSignupEnter(e){
        if (e.key === "Enter") {
            this.handle_signup()
        }
    }

    render() {
        return (
            <Router>
                <div className="wrapper">

                    {this.state.logged_in ?
                        <div className="content">
                            <Routes>
                                <Route path="/">
                                    <Route index
                                           element={
                                               <Home
                                                   funcLogout={this.handle_logout}
                                                   username={this.state.username}
                                               />
                                           }/>
                                    <Route path="edit/*"
                                           element={
                                               <Edit
                                                   funcLogout={this.handle_logout}
                                                   username={this.state.username}
                                               />
                                           }/></Route>
                            </Routes></div>
                        :
                        <>
                            <Modal show={this.state.displayed_input}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Параметры входа</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Button variant="success" style={{marginLeft: 86}}
                                            onClick={() => this.setState(
                                                {displayed_input: false, displayed_login: true})}
                                    >
                                        Авторизация
                                    </Button>
                                    <Button
                                        style={{marginLeft: 40}} variant="primary"
                                        onClick={() => this.setState(
                                            {displayed_input: false, displayed_signup: true})}
                                    >
                                        Регистрация
                                    </Button>
                                </Modal.Body>
                            </Modal>
                            <Modal show={this.state.displayed_signup} onHide={this.close_signup}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Регистрация</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form>
                                        <div className="data-block__grid data-block__grid--validity2">
                                            <fieldset className="fg data-block__col data-block__col12"
                                            >
                                                <label htmlFor="username">Логин</label>
                                                <input
                                                    type="text"
                                                    name="username"
                                                    value={this.state.username}
                                                    onChange={this.login_change}
                                                    onKeyDown={this.eventSignupEnter}
                                                />
                                            </fieldset>
                                        </div>

                                        <div className="data-block__grid data-block__grid--validity2">
                                            <fieldset className="fg data-block__col data-block__col12"
                                                      style={{marginBottom: 40}}>
                                                <label htmlFor="username">Пароль</label>
                                                <input
                                                    type="password"
                                                    name="password"
                                                    value={this.state.password}
                                                    onChange={this.pass_change}
                                                    onKeyDown={this.eventSignupEnter}
                                                />

                                            </fieldset>
                                        </div>
                                    </Form>

                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={this.close_signup}>
                                        Отмена
                                    </Button>
                                    <Button
                                        style={{marginRight: 40}} variant="primary"
                                        onClick={this.handle_signup}
                                    >
                                        Зарегистрироваться
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                            <Modal show={this.state.displayed_login} onHide={this.close_login}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Авторизация</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form>
                                        <div className="data-block__grid data-block__grid--validity2">
                                            <fieldset className="fg data-block__col data-block__col12"
                                            >
                                                <label htmlFor="username">Логин</label>
                                                <input
                                                    type="text"
                                                    name="username"
                                                    value={this.state.username}
                                                    onChange={this.login_change}
                                                    onKeyDown={this.eventLoginEnter}
                                                />
                                            </fieldset>
                                        </div>

                                        <div className="data-block__grid data-block__grid--validity2">
                                            <fieldset className="fg data-block__col data-block__col12"
                                                      style={{marginBottom: 40}}>
                                                <label htmlFor="username">Пароль</label>
                                                <input
                                                    type="password"
                                                    name="password"
                                                    value={this.state.password}
                                                    onChange={this.pass_change}
                                                    onKeyDown={this.eventLoginEnter}
                                                />

                                            </fieldset>
                                        </div>
                                    </Form>

                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={this.close_login}>
                                        Отмена
                                    </Button>
                                    <Button
                                        style={{marginRight: 40}} variant="primary"
                                        onClick={this.handle_login}
                                    >
                                        Войти
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </>
                    }
                </div>
            </Router>
        )
    }
}


export default App;
